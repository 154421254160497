import { URL_AGRONOTA, URL_SIMPLESCTE } from 'api/urls';
import { sistemas } from 'constants/sistemas';

const SystemsURL: { [key: number]: string } = {
  1: URL_SIMPLESCTE,
  2: URL_AGRONOTA,
};

const siteConfig = {
  siteName: 'Parceiro',
  siteIcon: '',
  logo: '/static/svgs/logo.svg',
  logoWhite: '',
  clientes: '/clientes',
  footerText: `© Copyright ${new Date().getFullYear()} Parceiro. Todos os direitos reservados.`,
};

const language = {
  languageId: 'portuguese',
  locale: 'pt',
  text: 'Português',
};

const langDir = 'ltr';

export { SystemsURL, langDir, language, siteConfig };

const _sistema = sistemas[window?.location?.hostname?.split('.')[1]];

export const contatoSuporte = `https://api.whatsapp.com/send?phone=${_sistema.whatsApp}`;
