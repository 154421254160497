import { auth } from './auth/auth';
import { clientes } from './clientes/clientes';
import { configuracoes } from './configuracoes/configuracoes';
import { convites } from './convites/convites';
import { dominio } from './dominio/dominio';
import { empresa } from './empresa/empresa';
import { escrituracaoAutomatica } from './escrituracaoAutomatica/escrituracaoAutomatica';
import { geral } from './geral/geral';
import { produtores } from './produtores/produtores';
import { produtos } from './produtos/produtos';
import { simuladorIR } from './simuladorIR/simuladorIR';
import { usuarios } from './usuarios/usuarios';
import { planoDeContas } from './planoDeContas/planoDeContas';
import { resumo } from './resumo/resumo';
import { naturezasOperacao } from './naturezasOperacao/naturezasOperacao';

export const api = {
  auth,
  geral,
  dominio,
  resumo,
  convites,
  empresa,
  clientes,
  produtores,
  planoDeContas,
  produtos,
  simuladorIR,
  usuarios,
  configuracoes,
  escrituracaoAutomatica,
  naturezasOperacao,
};
