import { QueryClientProvider } from '@tanstack/react-query';

import { ThemeProvider } from '@l_ine/core';
import { SmartProvider } from '@s_mart/core';

import { GlobalStyles } from 'styles/globalStyle';
import { lineTheme, smartTheme } from 'styles/themes/theme';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAnalytics } from 'hooks/useAnalytics';
import useClarity from 'hooks/useClarity';
import { useHead } from 'hooks/useHead';
import { useQueryClient } from 'hooks/useQueryClient';
import useSentry from 'hooks/useSentry';
import { enableMapSet } from 'immer';
import Router from 'routes/routes';
import { sistemas } from 'constants/sistemas';

enableMapSet();
const App = () => {
  const { initialize: initAnalytics } = useAnalytics();
  const { init: initClarity } = useClarity();

  useHead();
  useSentry();
  initClarity();
  initAnalytics();

  const _queryClient = useQueryClient();

  const dominio = window.location.hostname.split('.')[1];

  return (
    <>
      <title>Parceiro - {sistemas[dominio].name}</title>
      <QueryClientProvider client={_queryClient}>
        <ReactQueryDevtools position='bottom' buttonPosition='bottom-left' />
        <ThemeProvider theme={lineTheme}>
          <SmartProvider theme={smartTheme}>
            <GlobalStyles />
            <Router />
          </SmartProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
