import { axiosInstance } from 'api/axiosConfig';
import type {
  BuscarNaturezaOperacaoResponse,
  EditarNaturezaOperacaoBody,
  InserirNaturezaOperacaoBody,
  ListarNaturezasDisponiveisResponse,
} from './naturezasOperacao.types';
import { API_URL_AGRONOTA } from 'api/urls';

export const naturezasOperacao = {
  listarDisponiveis: (params?: { search: string }) =>
    axiosInstance.get<ListarNaturezasDisponiveisResponse>(
      `${API_URL_AGRONOTA}/parceiro/naturezas-operacao/disponiveis`,
      { params },
    ),

  buscar: (codigo: number) =>
    axiosInstance.get<BuscarNaturezaOperacaoResponse>(`${API_URL_AGRONOTA}/parceiro/naturezas-operacao/${codigo}`),

  inserir: (body: InserirNaturezaOperacaoBody) =>
    axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/naturezas-operacao/inserir`, body),

  alterarSituacao: (body: { codigo: number; ativa: boolean }) =>
    axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/naturezas-operacao`, body),

  editar: (codigo: number, body: EditarNaturezaOperacaoBody) =>
    axiosInstance.put(`${API_URL_AGRONOTA}/parceiro/naturezas-operacao/${codigo}`, body),
};
