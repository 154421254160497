// relação emp - url dos whitelabels
export const URL_WHITELABELS_AGRONOTA = {
  2703: 'https://escritoriovip.agronota.com.br',
  4593: 'https://app.startja.com.br',
  6911: 'https://fetag.agronota.com.br',
  6678: 'https://app.iragrogestao.com.br',
  8048: 'https://app.agrilogic.com.br',
  8809: 'https://app.eecontabil.com.br',
  10428: 'https://app.jaymeconsultoria.com.br',
  2416: 'https://agro.planaconpf.com.br',
  2423: 'https://agro.planaconpf.com.br',
  3984: 'https://app.escritoriopedrasaltas.com.br',
  8559: 'https://app.contagri.net',
};
