import { listQueryParamsSchema, metaSchema } from 'api/axiosConfig';
import { ZodApiResponseParser } from 'api/zodApi';
import { ZodApiParamsParser } from 'api/zodApi';
import { z } from 'zod';

export const schemaItemListarProdutosResponse = z.object({
  codigo: z.number(),
  funrural: z.number(),
  funruralPj: z.number(),
  ncm: z.number(),
  nome: z.string(),
  pertence_parceiro: z.boolean(),
  unidade: z.string().nullable(),
});

const _schemaListarProdutosResponse = z.object({
  data: z.array(schemaItemListarProdutosResponse),
  meta: metaSchema,
});

const _schemaInserirProdutoResponse = z.object({
  data: z.object({
    classificacao: z.number(),
    codigoBarra: z.string().nullable(),
    produtoNome: z.string(),
    codigo: z.number(),
  }),
});

const _schemaListarProdutosParams = listQueryParamsSchema.optional();

export const parseListarProdutosResponse = ZodApiResponseParser.createParser(_schemaListarProdutosResponse);

export const parseListarProdutosParams = ZodApiParamsParser.createParser(_schemaListarProdutosParams);

export type ListarProdutosResponse = z.output<typeof _schemaListarProdutosResponse>;

export type ItemListarProdutosResponse = z.output<typeof schemaItemListarProdutosResponse>;

export type InserirProdutoResponse = z.output<typeof _schemaInserirProdutoResponse>;

export type ListarProdutosParams = z.output<typeof _schemaListarProdutosParams>;
