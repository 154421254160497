import { useMemo } from 'react';
import { api } from 'api/api';
import { HandleSubmitProps, Passos } from './integrarComDominioDialog.types';
import { useIntegrarComDominioDialogStore } from './integrarComDominioDialog.store';
import useAppStore from 'store/app/app';
import useAuthStore from 'store/auth/auth';
import { useClientesStore } from 'store/clientes/clientes';
import useSistema from 'hooks/useSistema';
import { VerificarChaveDominioResponse } from 'api/dominio/dominio.types';
import { AxiosError } from 'axios';
import { Link } from '@mui/material';

export const ErrosValidacaoChave = {
  CHAVE_INVALIDA: <>Não conseguimos nos conectar com a Domínio. Confira a chave inserida e tente novamente.</>,
  CNPJ_INVALIDO: <>O CNPJ da chave não é o mesmo da sua empresa. Verifique com o seu contador e tente novamente.</>,
  KEY_JA_ATIVADA: (
    <>
      A chave utilizada já está em uso em outra integração. Gere uma nova chave de integração no sistema Domínio ou{' '}
      <Link
        href='https://suporte.dominioatendimento.com/central/faces/solucao.html?codigo=8536&palavraChave=chave%20api&modulosSelecionados=0'
        target='_blank'
      >
        clique aqui
      </Link>{' '}
      para saber como gerar a nova chave.
    </>
  ),
};

export function useIntegrarComDominioDialog() {
  const { setLoading, handleErrors, setNotification } = useAppStore();
  const { perfil } = useAuthStore();
  const { passo, dadosCliente, handleProximoPasso, handleVoltarPasso, setDadosIntegracao, handleClose } =
    useIntegrarComDominioDialogStore();
  const { code: codigoSistema } = useSistema();
  const { buscarClientes } = useClientesStore();

  const tituloDialog = useMemo(() => {
    switch (passo) {
      case Passos.INTEGRAR_COM_DOMINIO:
        return 'Integrar com a Domínio';
      case Passos.CONFIRMAR_DADOS:
        return 'Confirme os dados';
    }
  }, [passo]);

  const validarErrodaChave = (
    dados: VerificarChaveDominioResponse['data'] | null,
  ): { error: React.ReactNode } | undefined => {
    if (dados === null) {
      return { error: ErrosValidacaoChave.CHAVE_INVALIDA };
    }
    if (dados.clienteCnpj !== dadosCliente?.cpfCnpj) {
      return {
        error: ErrosValidacaoChave.CNPJ_INVALIDO,
      };
    }
  };
  const handleVerificarChave = async (chave: string): Promise<{ error: React.ReactNode } | undefined> => {
    try {
      setLoading(true);
      const response = await api.dominio.verificarChave(chave);

      const erroChave = validarErrodaChave(response.data.data);

      if (erroChave?.error) {
        setDadosIntegracao(response.data.data);
      }
      return erroChave;
    } catch (error) {
      console.error(error);
      return { error: ErrosValidacaoChave.CHAVE_INVALIDA };
    } finally {
      setLoading(false);
    }
  };

  async function handleConfirmarDados(
    values: HandleSubmitProps['values'],
  ): Promise<{ error: React.ReactNode } | undefined> {
    try {
      setLoading(true);

      if (dadosCliente?.codigo && perfil?.emp) {
        await api.dominio.salvarDados({
          chave: values.chaveDominio,
          codigoCliente: dadosCliente.codigo,
          codigoParceiro: perfil.emp,
        });
        if (codigoSistema === 2) {
          await api.dominio.sincronizarDadosAgronota(dadosCliente.codigo);
        } else if (codigoSistema === 1) {
          await api.dominio.sincronizarDadosSimplesCTE(dadosCliente.codigo);
        }

        handleClose();
        setNotification({ title: 'Empresa sincronizada com sucesso' });
        buscarClientes();
      }
      return { error: <>Não foi possível confirmar os dados</> };
    } catch (error: any) {
      if (
        error instanceof AxiosError &&
        error.response?.data?.erro?.mensagem?.includes('Integration Key already activated')
      ) {
        return { error: ErrosValidacaoChave.KEY_JA_ATIVADA };
      }
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(values: HandleSubmitProps['values'], methods: HandleSubmitProps['methods']) {
    switch (passo) {
      case Passos.INTEGRAR_COM_DOMINIO:
        {
          const result = await handleVerificarChave(values.chaveDominio);
          if (result?.error) {
            methods.setValue('erroChaveDominio', result.error);
          } else {
            handleProximoPasso();
          }
        }
        return;
      case Passos.CONFIRMAR_DADOS: {
        const result = await handleConfirmarDados(values);
        if (result?.error) {
          methods.setValue('erroChaveDominio', result.error);
          handleVoltarPasso();
        }
      }
    }
  }

  return {
    tituloDialog,
    handleSubmit,
  };
}
