import { create } from 'zustand';

import type { ClientesState, ClientesStore } from './clientes.types';

import { api } from 'api/api';
import useAppStore from 'store/app/app';
import { SystemsURL } from 'config';
import useAuthStore from 'store/auth/auth';
import { URL_WHITELABELS_AGRONOTA } from 'constants/whitelabelsAgronota';
import { SituacoesClientesAgronota } from 'containers/clientesAgronota/filtrosClientesAgronota/filtrosClientesAgronota.types';

const _initialState: ClientesState = {
  clientes: {
    data: [],
    extra: {
      ativos: 0,
      bloqueados: 0,
      inativos: 0,
      sisCodigo: 0,
    },
    meta: {
      current_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  loading: false,
  // Agronota
  search: '',
  filtroSituacao: SituacoesClientesAgronota.ATIVOS,
};

export const useClientesStore = create<ClientesStore>((set, get) => ({
  ..._initialState,
  buscarClientes: async (options) => {
    try {
      set({ loading: true });

      const { data } = await api.clientes.listar(options);

      set((state) => ({
        clientes: {
          meta: data.meta,
          extra: data.extra,
          data:
            data.meta.current_page > 1
              ? [...state.clientes.data, ...data.data]
              : data.data,
        },
      }));
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      set({ loading: false });
    }
  },
  inserirCliente: async (payload, sistema, callback) => {
    try {
      useAppStore.getState().setLoading(true);

      const { data: response } = await api.clientes.inserir(payload);

      useAppStore.getState().setNotification({
        title:
          sistema === 1 ? 'Cliente adicionado' : 'Grupo criado com sucesso',
      });

      get().buscarClientes();

      callback?.(response.data);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      useAppStore.getState().setLoading(false);
    }
  },
  enviarRecomendacao: async (payload, callback) => {
    try {
      useAppStore.getState().setNotification({
        title: 'Recomendação enviada',
        message: 'Enviada para o e-mail indicado',
      });

      callback?.();
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  redirecionarConta: async ({ codigo, sisCodigo }) => {
    const profile = useAuthStore.getState().perfil;
    const setLoading = useAppStore.getState().setLoading;
    try {
      setLoading(true);
      const { data } = await api.auth.buscarTokenRedirect(codigo, sisCodigo);

      const _empCodigo = profile.emp;
      const _sistemaUrl = SystemsURL[sisCodigo];

      let _sistemaWhiteLabelUrl: string | undefined;

      if (_sistemaUrl.includes('agronota.com.br')) {
        _sistemaWhiteLabelUrl = URL_WHITELABELS_AGRONOTA[_empCodigo];
      }

      const queryParams = new URLSearchParams([
        ['token', data.token],
        ['origem', 'parceiro'],
      ]);

      const url = `${_sistemaWhiteLabelUrl || _sistemaUrl}${
        _sistemaUrl?.includes('agronota') ? '/redirect' : ''
      }?${queryParams}`;

      window.open(url, '_blank');
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      setLoading(false);
    }
  },
  baixarXML: async (payload, callback) => {
    try {
      set({ loading: true });
      const { data } = await api.clientes.baixarXML(payload);

      callback?.(data.data);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      set({ loading: false });
    }
  },
  compartilharXML: async (payload, callback) => {
    try {
      set({ loading: true });

      await api.clientes.enviarEmailXML(payload);

      useAppStore.getState().setNotification({
        title: 'XMLs enviados com sucesso',
      });

      callback?.();
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      set({ loading: false });
    }
  },
  setSearch(search) {
    set({ search });
  },
  setFiltroSituacao(filtro) {
    set({ filtroSituacao: filtro });
  },
  limparStore() {
    set(_initialState);
  },
}));
